<template>
  <div class="register-header">
    <div class="register-header__title tw-flex tw-items-end">
      <h1
        v-text="title"
        class="tw-text-xl tw-font-normal tw-flex-1 tw-pr-2"
        data-test="title"
      />
      <div
        v-text="steps"
        class="tw-text-xs tw-mb-2"
        data-test="steps"
      />
    </div>
    <p
      v-if="suffix"
      v-text="suffix"
      class="tw-text-secondary-text tw-italic tw-text-xs tw-mt-1"
      data-test="suffix"
    />
  </div>
</template>

<script>
  /**
   * @module component - RegisterHeader
   * @param {string} title
   * @param {string} steps
   * @param {string} [suffix=null]
   */
  export default {
    name: 'RegisterHeader',
    props: {
      title: {
        type: String,
        required: true
      },
      steps: {
        type: String,
        required: true
      },
      suffix: {
        type: String,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>
.register-header__title {
  position: relative;
}
.register-header__title::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: '';
  height: 1px;
  background-color: $divider;
}
</style>
