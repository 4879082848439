<template>
  <div class="register-navigation tw-flex tw-items-center tw-justify-between tw-px-4 sm:tw-px-8 md:tw-px-16 tw-py-5 tw-fixed tw-left-0 tw-w-full tw-bottom-0 md:tw-relative tw-bg-white tw-z-20">
    <!-- Progress bar displayed only on mobile devices -->
    <div
      class="register-navigation__progress tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-2 md:tw-hidden tw-bg-gray-300"
      data-test="progress"
    >
      <div
        :style="{
          'width': `${progress}%`
        }"
        class="register-navigation__progress__inner tw-absolute tw-left-0 tw-h-full tw-top-0 tw-rounded-tr tw-rounded-br"
      />
    </div>

    <div>
      <UiButton
        type="button"
        variant="link"
        data-test="back"
        @click="$emit('back')"
      >
        <template #left-icon>
          <ui-material-icon
            name="keyboard_arrow_left"
            data-test="icon"
          />
        </template>
        <span
          v-text="$options.filters.capitalize($t('back'))"
        />
      </UiButton>
    </div>
    <div>
      <UiButton
        :disabled="submitDisabled"
        type="submit"
        variant="primary"
        data-test="submit"
        @click.native="$emit('submit')"
      >
        {{ $t('app.buttons.next') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * @module component - RegisterNavigation
   * @param {boolean} [submitDisabled=false]
   * @emits submit
   * @emits back
   */
  export default {
    name: 'RegisterNavigation',
    props: {
      submitDisabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('auth/register', [
        'getCompanyProgress',
        'getTransportProgress',
        'getAccountProgress'
      ]),
      /**
       * Returns the combined progress of the 3 steps.
       * @function progress
       * @returns {Number}
       */
      progress () {
        return ((this.getCompanyProgress + this.getTransportProgress + this.getAccountProgress) * 100) / 300
      }
    }
  }
</script>

<style lang="scss" scoped>

  .register-navigation {
    border-top: 1px solid $divider;

    &__progress {
      top: -0.5rem;

      &__inner {
        transition: width 200ms ease-in-out;
        background: $primary;
        background: linear-gradient(90deg, $primary-darken 0%, $primary 100%);
      }
    }
  }

</style>
