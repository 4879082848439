<template>
  <div
    class="register"
  >
    <div
      class="register__content tw-py-4 md:tw-pb-10"
      data-test="content"
    >
      <h1
        class="register__content__title tw-text-3xl tw-font-light tw-text-center tw-mb-4"
        v-text="$t('auth.titles.register')"
        data-test="title"
      />
      <h2
        class="register__content__subtitle tw-text-gray-600 tw-text-xl tw-font-light tw-text-center"
        v-text="$t('auth.titles.register_subtitle')"
        data-test="subtitle"
      />
    </div>
    <ValidationObserver
      ref="observer"
    >
      <form
        :disabled="!formData.userType"
        class="tw-flex tw-flex-col"
        data-test="form"
        @submit.prevent="submitted"
      >
        <ui-fat-radio-group
          v-model="formData.userType"
          :items="userTypes"
          class="register__content__user-types tw-flex tw-mb-5"
          data-test="types"
        >
          <template slot-scope="{ item, active, keydown }">
            <ui-fat-radio-item
              :title="item.title"
              :active="active"
              @keydown.native="keydown"
              @click.native="formData.userType = item.value"
            >
              <img
                :src="item.icon"
                alt=""
                height="35"
                class="tw-object-contain tw-mb-4 tw-select-none tw-pointer-events-none"
              >
              <div
                v-text="item.title"
                class="register__content__user-types__title"
              />
            </ui-fat-radio-item>
          </template>
        </ui-fat-radio-group>

        <ui-button
          :disabled="!formData.userType || $wait.is('fetching questions')"
          :loading="$wait.is('fetching questions')"
          data-test="submit"
          variant="primary"
          size="md"
          class="tw-mx-auto tw-w-full"
          type="submit"
        >
          {{ $t('auth.buttons.register.get_started') }}
        </ui-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import store from '@/store'

  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'
  import UiFatRadioItem from '@/components/UI/FatRadioGroup/FatRadioItem/index.vue'

  /**
   * @module view - Register
   */
  export default defineComponent({
    name: 'Register',
    components: {
      UiFatRadioGroup,
      UiFatRadioItem
    },
    // @ts-ignore
    metaInfo () {
      return {
        title: this.$t('auth.titles.register')
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        formData: {
          userType: null
        },
        userTypes: [
          {
            // @ts-ignore
            icon: require('@/assets/img/icons/shipper.svg'),
            title: this.$t('auth.buttons.register.shipper'),
            value: 'shipper'
          },
          {
            // @ts-ignore
            icon: require('@/assets/img/icons/carrier.svg'),
            title: this.$t('auth.buttons.register.carrier'),
            value: 'carrier'
          }
        ]
      }
    },
    methods: {
      submitted () {
        if (!this.formData.userType) return

        this.$router.push({
          name: 'RegisterCompany',
          params: {
            userType: this.formData.userType
          }
        })
          .catch(() => {})
      }
    }
  })
</script>

<style lang="scss">
.register__content__user-types .ui-fat-radio-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 50%;
  min-height: 140px;
}
.register__content__user-types .ui-fat-radio-item img {
  filter: invert(39%) sepia(8%) saturate(152%) hue-rotate(161deg) brightness(102%) contrast(91%);
  height: 40px;
}
.register__content__user-types .ui-fat-radio-item.active img {
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(282deg) brightness(250%) contrast(104%);
}
.register__content__user-types .ui-fat-radio-item__container {
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 0px;
}
.register__content__user-types__title {
  min-height: 3rem;
}
.register__content__user-types .ui-fat-radio-item:first-child {
  margin-right: 1rem;
}
</style>
