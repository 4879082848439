<template>
  <div class="auth-header tw-flex tw-justify-between tw-items-center tw-mt-3 tw-px-3">
    <router-link
      :to="{ name: 'AuthView' }"
      class="auth-header__logo"
    >
      <picture>
        <source
          srcset="@/assets/img/logo_bg_white.svg"
          media="(max-width: 425px)"
        >
        <img
          src="@/assets/img/logo_bg_white.svg"
          alt="Logo Chronotruck"
          height="38"
        >
      </picture>
    </router-link>
    <div
      v-if="$route.name === 'Register'"
    >
      <span
        v-text="$t('auth.labels.already_registered')"
        class="tw-text-sm tw-text-gray-600 tw-hidden lg:tw-inline"
        data-test="already-registered"
      />
      <UiButton
        :to="{
          name: 'SignIn'
        }"
        variant="info"
        size="sm"
        class="tw-ml-2"
        data-test="sign-in"
      >
        {{ $t('auth.buttons.sign_in') }}
      </UiButton>
    </div>
    <div
      v-else
    >
      <span
        v-text="$t('auth.labels.no_account_yet')"
        class="tw-text-sm tw-text-gray-600 tw-hidden lg:tw-inline"
        data-test="no-account"
      />
      <UiButton
        :to="{
          name: 'Register'
        }"
        variant="info"
        size="sm"
        class="tw-ml-2"
        data-test="sign-up"
      >
        {{ $t('auth.buttons.sign_up') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - AuthHeader
   */
  export default {
    name: 'AuthHeader'
  }
</script>

<style lang="scss" scoped>

  .auth-header {
    flex: 0 0 55px;
  }

</style>
