<template>
  <div class="explanation-carousel">
    <div class="explanation-carousel__pagination">
      <button
        v-for="(explanation, k) in explanations"
        :key="k"
        :title="explanation.title"
        :class="{
          active: page === k
        }"
        class="explanation-carousel__pagination__item tw-cursor-pointer tw-appearance-none tw-border-none"
        @click="slideTo(k)"
      />
    </div>
    <carousel
      ref="carousel"
      v-model="page"
      :per-page="1"
      :navigate-to="page"
      :loop="true"
      :pagination-enabled="false"
      class="tw-w-full"
    >
      <slide
        v-for="(explanation, k) in explanations"
        :key="k"
      >
        <div class="explanation-carousel__slide">
          <h3 class="explanation-carousel__slide__title tw-font-light tw-text-white tw-text-lg">
            {{ explanation.title }}
          </h3>
          <p class="explanation-carousel__slide__content tw-font-light tw-text-white tw-text-base">
            {{ explanation.content }}
          </p>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
  /**
   * @module component - ExplanationCarousel
   * @param {Array} explanations
   */
  export default {
    name: 'ExplanationCarousel',
    props: {
      explanations: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        interval: null,
        page: 0
      }
    },
    mounted () {
      this.play()
    },
    beforeDestroy () {
      this.stop()
    },
    methods: {
      /**
       * Starts the auto slide
       * @function play
       */
      play () {
        this.interval = setInterval(() => this.next(), 5000)
      },
      /**
       * Stops the auto slide
       * @function stop
       */
      stop () {
        if (this.interval) {
          clearInterval(this.interval)
        }
      },
      /**
       * Goes to the next slide
       * @function next
       */
      next () {
        const carousel = this.$refs.carousel
        if (carousel) this.slideTo(carousel.getNextPage())
      },
      slideTo (i) {
        this.stop()
        this.play()

        const carousel = this.$refs.carousel
        if (carousel) carousel.goToPage(i)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .explanation-carousel {
    &__pagination {
      padding: 0 32px;

      &__item {
        height: 5px;
        width: 40px;
        margin-right: 1px;
        background-color: rgba(white, 0.35);
        transition: background-color 200ms ease-in-out;

        &:hover:not(.active) {
          background-color: rgba(white, 0.5);
        }

        &.active {
          background-color: white;
        }
      }
    }

    &__slide {
      padding: 32px;
    }
  }

</style>
